import React, { useEffect, useState, useCallback } from 'react';
import './App.css';
import Login from './login';
import { getTokenFromUrl, spotify } from './spotify';

const WELSH_PLAYLIST_ID = '0NcBJk5mJKEwCpOdv2jrYP';

function App() {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [topTracks, setTopTracks] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getAudioFeaturesSimilarity = useCallback(async (topTracks, welshTracks) => {
    const topTracksFeatures = await spotify.getAudioFeaturesForTracks(topTracks.map(t => t.id));
    const welshTracksFeatures = await spotify.getAudioFeaturesForTracks(welshTracks.map(t => t.id));
    
    return welshTracks.map((track, i) => {
      const similarity = topTracksFeatures.audio_features.reduce((sum, topTrackFeature) => {
        return sum + calculateFeatureSimilarity(topTrackFeature, welshTracksFeatures.audio_features[i]);
      }, 0) / topTracksFeatures.audio_features.length;
      return { ...track, similarity };
    });
  }, []);

  const calculateFeatureSimilarity = (feature1, feature2) => {
    const relevantFeatures = ['danceability', 'energy', 'valence', 'tempo', 'acousticness'];
    return relevantFeatures.reduce((sum, feature) => {
      return sum + (1 - Math.abs(feature1[feature] - feature2[feature]));
    }, 0) / relevantFeatures.length;
  };

  const getGenreSimilarity = useCallback(async (topTracks, welshTracks) => {
    const topArtists = await Promise.all(topTracks.flatMap(t => t.artists.map(a => spotify.getArtist(a.id))));
    const topGenres = new Set(topArtists.flatMap(artist => artist.genres));
    
    return welshTracks.map(track => {
      const artistGenres = new Set(track.artists.flatMap(a => a.genres || []));
      const genreOverlap = [...artistGenres].filter(genre => topGenres.has(genre)).length;
      return { ...track, genreScore: genreOverlap };
    });
  }, []);

  const getPopularityAndDateSimilarity = useCallback((topTracks, welshTracks) => {
    const avgPopularity = topTracks.reduce((sum, t) => sum + t.popularity, 0) / topTracks.length;
    const avgReleaseYear = topTracks.reduce((sum, t) => sum + new Date(t.album.release_date).getFullYear(), 0) / topTracks.length;
    
    return welshTracks.map(track => {
      const popularityDiff = Math.abs(track.popularity - avgPopularity);
      const yearDiff = Math.abs(new Date(track.album.release_date).getFullYear() - avgReleaseYear);
      const score = (1 / (popularityDiff + 1)) + (1 / (yearDiff + 1));
      return { ...track, score };
    });
  }, []);

  const getRecommendations = useCallback(async (topTracks, welshTracks) => {
    const [audioSimilar, genreSimilar, popDateSimilar] = await Promise.all([
      getAudioFeaturesSimilarity(topTracks, welshTracks),
      getGenreSimilarity(topTracks, welshTracks),
      getPopularityAndDateSimilarity(topTracks, welshTracks)
    ]);
    
    const combinedScores = welshTracks.map(track => {
      const audioScore = audioSimilar.find(t => t.id === track.id)?.similarity || 0;
      const genreScore = genreSimilar.find(t => t.id === track.id)?.genreScore || 0;
      const popDateScore = popDateSimilar.find(t => t.id === track.id)?.score || 0;
      return { ...track, totalScore: audioScore + genreScore + popDateScore };
    });

    return combinedScores.sort((a, b) => b.totalScore - a.totalScore).slice(0, 12);
  }, [getAudioFeaturesSimilarity, getGenreSimilarity, getPopularityAndDateSimilarity]);

  const getRecommendationReason = useCallback((welshTrack, userTopTracks) => {
    if (!userTopTracks || userTopTracks.length === 0) {
      return "This track has interesting musical characteristics.";
    }

    const matchingArtist = userTopTracks.find(track => track.artists[0].name === welshTrack.artists[0].name);
    if (matchingArtist) {
      return `We think you'll like this because you've recently listened to ${matchingArtist.name} by ${matchingArtist.artists[0].name}.`;
    }

    const topGenres = new Set(userTopTracks.flatMap(track => track.artists[0].genres || []));
    const matchingGenre = (welshTrack.artists[0].genres || []).find(genre => topGenres.has(genre));
    if (matchingGenre) {
      return `This track is in the ${matchingGenre} genre, which appears in your recent top tracks.`;
    }

    const randomTopTrack = userTopTracks[Math.floor(Math.random() * userTopTracks.length)];
    return `This track has similar musical characteristics to ${randomTopTrack.name} by ${randomTopTrack.artists[0].name} in your recent top tracks.`;
  }, []);

  const createPlaylistWithRecommendations = useCallback(async (userId, tracks) => {
    try {
      const date = new Date().toLocaleDateString();
      const playlistName = `Miwsig Mêt Recommendations ${date}`;
      const playlist = await spotify.createPlaylist(userId, {
        name: playlistName,
        description: 'Welsh music recommendations from Miwsig Mêt',
        public: false
      });

      await spotify.addTracksToPlaylist(playlist.id, tracks.map(track => track.uri));

      return playlist;
    } catch (error) {
      console.error("Error creating playlist:", error);
      throw error;
    }
  }, []);

  const handleCreatePlaylist = useCallback(async () => {
    try {
      setLoading(true);
      const playlist = await createPlaylistWithRecommendations(user.id, recommendations);
      alert(`Playlist "${playlist.name}" created successfully!`);
    } catch (error) {
      setError("Failed to create playlist. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [user, recommendations, createPlaylistWithRecommendations]);

  useEffect(() => {
    const hash = getTokenFromUrl();
    window.location.hash = "";
    const _token = hash.access_token;

    if (_token) {
      setToken(_token);
      spotify.setAccessToken(_token);
      
      const fetchData = async () => {
        try {
          const userData = await spotify.getMe();
          setUser(userData);

          const topTracksData = await spotify.getMyTopTracks({ 
            limit: 50, 
            time_range: 'short_term'
          });
          setTopTracks(topTracksData.items);

          const welshPlaylistTracks = await spotify.getPlaylistTracks(WELSH_PLAYLIST_ID);
          const welshTracks = welshPlaylistTracks.items.map(item => item.track);

          const recommendedTracks = await getRecommendations(topTracksData.items, welshTracks);
          setRecommendations(recommendedTracks);

          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setError("Failed to load data. Please try again.");
          setLoading(false);
        }
      };

      fetchData();
    } else {
      setLoading(false);
    }
  }, [getRecommendations]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="app">
      {token ? (
        <div className="content">
          <header>
            <h1>Croeso i Miwsig Mêt, {user?.display_name}!</h1>
            {user?.images?.[0]?.url && (
              <img src={user.images[0].url} alt="Profile" className="profile-pic"/>
            )}
          </header>
          
          <section className="recommendations">
            <h2>Recommended Welsh Tracks</h2>
            <p className="intro-text">Based on your recent listening preferences, here are some Welsh-language tracks you might enjoy:</p>
            <button onClick={handleCreatePlaylist} className="create-playlist-btn">
              Add Recommendations to Playlist
            </button>
            <div className="track-list">
              {recommendations.map(track => (
                <div key={track.id} className="track-item">
                  <img src={track.album.images[0].url} alt={track.name} className="track-image"/>
                  <div className="track-info">
                    <p className="track-name">{track.name}</p>
                    <p className="track-artist">{track.artists[0].name}</p>
                    {track.preview_url && (
                      <audio controls src={track.preview_url}>
                        Your browser does not support the audio element.
                      </audio>
                    )}
                    <p className="recommendation-reason">{getRecommendationReason(track, topTracks)}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;